import React from 'react';
import { ContentBlock, Strings, StringsWrapper } from '@fragments';
import { useStateScreenMobile } from '@helpers/hooks';
import {
  SectionClimateChangeContainer,
  SectionClimateContent,
  SectionClimateChangeVector,
} from './SectionClimateChange.styled';

const SectionClimateChange = () => {
  return (
    <StringsWrapper>
      {useStateScreenMobile() ? (
        <>
          <Strings name="fund-climate--mobile-1" height={155} top={-60} mobile />
          <Strings name="fund-climate--mobile-2" height={352} top={200} mobile />
        </>
      ) : (
        <Strings name="fund-climate" height={613} top={-100} />
      )}
      <SectionClimateChangeContainer>
        <SectionClimateChangeVector />
        <SectionClimateContent>
          <ContentBlock
            headline="Climate change"
            content={`Caused by human activities, climate change is going to bring massive and harmful consequences.
            The health of our environment needs to be treated seriously if we want to ensure good
            living conditions for our descendants and other living organisms.
            It’s time to take the natural environment into account in our economy.`}
          />
        </SectionClimateContent>
      </SectionClimateChangeContainer>
    </StringsWrapper>
  );
};

export default SectionClimateChange;
