import React from 'react';
import { Container, Strings, StringsWrapper } from '@fragments';
import { useStateScreenMobile } from '@helpers/hooks';
import dataFund from '../Fund.data';
import Statement from './SectionStatement.styled';

const {
  sectionStatement: { content },
} = dataFund;

const SectionStatement = () => (
  <StringsWrapper>
    {useStateScreenMobile() ? (
      <Strings name="fund-criteria--mobile" height={238} top={-115} mobile />
    ) : (
      <Strings name="fund-criteria" height={450} top={-240} />
    )}
    <Container>
      <Statement>{content}</Statement>
    </Container>
  </StringsWrapper>
);

export default SectionStatement;
