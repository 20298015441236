import React from 'react';
import { TextEffect } from '@fragments';

const dataFund = {
  sectionHeader: {
    name: 'Ragnarson Fund',
    headline: (
      <>
        We <TextEffect effect="highlight">invest</TextEffect> in early-stage impact-driven startups
      </>
    ),
    content: `Startups can potentially solve a lot of problems in our economy and society.
      We invest in ones that we believe in with cash,
      know-how, and software development services.`,
    ctaObj: {
      to: '#fund-criteria',
      label: 'Show criteria',
      title: 'Go to criteria section',
    },
    linkArray: [
      {
        to: '/advisory',
        label: 'Advisory',
        title: 'Go to Advisory',
      },
      {
        to: '/software-development',
        label: 'Software development',
        title: 'Go to Software development',
      },
    ],
  },
  sectionStartup: {
    callout: (
      <>
        Startups <br /> we <br /> seek
      </>
    ),
    list: [
      {
        blockContent: 'Ideally, you are an early-stage startup with an MVP or pre-product.',
      },
      {
        blockContent: 'Creating a positive impact is as important as becoming a profitable organisation.',
      },
      {
        blockContent: 'Software is an important part of your solution.',
      },
      {
        blockContent: 'You’re looking to raise a pre-seed round.',
      },
    ],
  },
  sectionStatement: {
    content: (
      <>
        <TextEffect effect="highlight">If you meet</TextEffect>
        our criteria, contact us via form below, attach your pitch deck and a brief introduction.
      </>
    ),
  },
  contactFormHeader: 'Apply',
};

export default dataFund;
