import styled from 'styled-components';
import { spaceDt, spaceMb, screenMin } from '@helpers/styles';
import { toVW } from '@helpers/methods';
import { Container } from '@fragments';
import { TreeBranch } from '@illustrations';

const SectionClimateChangeContainer = styled(Container)`
  padding-left: ${spaceMb(2)};
  padding-right: auto;

  ${screenMin('lg')} {
    flex-direction: row;
    align-items: center;
    padding-left: ${spaceDt(11)};
    padding-right: auto;
    margin-bottom: ${spaceDt(28)};
  }
`;
const SectionClimateContent = styled.div`
  padding-left: ${spaceMb(2)};
  max-width: ${toVW(262, 'mobile')};
  ${screenMin('lg')} {
    padding-left: ${spaceDt(8)};
    max-width: ${toVW(805, 'desktop')};
  }
`;
const SectionClimateChangeVector = styled(TreeBranch)`
  width: ${toVW(318, 'mobile')};
  height: ${toVW(364, 'mobile')};

  ${screenMin('lg')} {
    width: ${toVW(336, 'desktop')};
    height: ${toVW(385, 'desktop')};
  }
`;
export { SectionClimateChangeContainer, SectionClimateContent, SectionClimateChangeVector };
