import React from 'react';
import { ContentBlock, Strings, StringsWrapper } from '@fragments';
import { useStateScreenMobile } from '@helpers/hooks';
import SectionSustainabilityContainer from './SectionSustainability.styled';

const SectionSustainability = () => {
  return (
    <StringsWrapper>
      {useStateScreenMobile() ? (
        <Strings name="fund-sustainability--mobile" height={419} top={80} mobile />
      ) : (
        <Strings name="fund-sustainability" height={1303} top={-80} />
      )}
      <SectionSustainabilityContainer>
        <ContentBlock
          headline="Lack of sustainability"
          content={`We live in a throw-away economy where the concern for natural resources and pollution of
          our planet emerged only as a recent phenomenon. There still exists a huge gap between
          our consumption habits and the ability of Earth to replenish itself.
          We need to close this gap as soon as possible. `}
        />
      </SectionSustainabilityContainer>
    </StringsWrapper>
  );
};

export default SectionSustainability;
