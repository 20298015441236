import styled from 'styled-components';
import { spaceDt, spaceMb, screenMin } from '@helpers/styles';
import { toVW } from '@helpers/methods';
import { Container } from '@fragments';

const SectionSustainabilityContainer = styled(Container)`
  align-items: center;
  padding-left: ${spaceMb(9)};
  padding-right: ${spaceMb(3)};
  min-width: ${toVW(261, 'mobile')};
  ${screenMin('lg')} {
    flex-direction: row;
    padding-left: ${spaceDt(26)};
    padding-right: auto;
    margin-bottom: ${spaceDt(28)};
  }
`;

export default SectionSustainabilityContainer;
