import React from 'react';
import { Strings, StringsWrapper } from '@fragments';
import { useStateScreenMobile } from '@helpers/hooks';
import dataFund from '../Fund.data';
import {
  SectionCriteriaBase,
  SectionCriteriaIllustration,
  SectionCriteriaTitle,
  SectionCriteriaList,
  SectionCriteriaBlock,
} from './SectionCriteria.styled';

const {
  sectionStartup: { callout, list },
} = dataFund;

const SectionCriteria = () => (
  <StringsWrapper>
    {useStateScreenMobile() && <Strings name="fund-startups--mobile" height={301} top={-40} mobile />}
    <SectionCriteriaBase id="fund-criteria">
      <SectionCriteriaIllustration />
      <SectionCriteriaTitle>{callout}</SectionCriteriaTitle>

      <SectionCriteriaList>
        {list.map(({ blockContent }, index) => (
          <SectionCriteriaBlock key={index}>{blockContent}</SectionCriteriaBlock>
        ))}
      </SectionCriteriaList>
    </SectionCriteriaBase>
  </StringsWrapper>
);

export default SectionCriteria;
