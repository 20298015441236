import styled from 'styled-components';
import { toVW } from '@helpers/methods';
import { spaceMb, spaceDt, getTypography, screenMin } from '@helpers/styles';

const Statement = styled.div`
  margin-bottom: ${spaceMb(4.5)};

  ${getTypography('heading-3')}

  ${screenMin('lg')} {
    margin-bottom: ${spaceDt(10)};
    padding-left: ${spaceDt(16)};
    max-width: ${toVW(680, 'desktop')};
  }
`;

export default Statement;
