import styled from 'styled-components';
import { toVW } from '@helpers/methods';
import { color, screenMin, screenMax, getTypography, spaceMb, spaceDt } from '@helpers/styles';
import { VikingHandDark } from '@illustrations';
import { Block } from '../components';

const SectionCriteriaBase = styled.div`
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${spaceMb(10.5)};

  ${screenMin('lg')} {
    margin-bottom: ${spaceDt(30.5)};
  }
`;

const SectionCriteriaIllustration = styled(VikingHandDark)`
  width: ${toVW(207, 'mobile')};
  height: ${toVW(255, 'mobile')};
  margin-left: ${toVW(-35, 'mobile')};

  ${screenMin('lg')} {
    margin-left: ${toVW(-100, 'desktop')};
    width: ${toVW(424, 'desktop')};
    height: ${toVW(524, 'desktop')};
  }
`;

const SectionCriteriaTitle = styled.h2`
  margin-top: ${spaceMb(8.5, 'mobile')};

  ${screenMin('lg')} {
    margin-top: ${spaceMb(4.5, 'desktop')};
  }

  ${getTypography('callout-1')}
`;

const SectionCriteriaList = styled.div`
  flex-grow: 1;
  margin-right: ${toVW(-1, 'mobile')};
  margin-left: ${toVW(99, 'mobile')};

  ${screenMax('lg')} {
    margin-top: ${spaceMb(-4)};
  }

  ${screenMin('lg')} {
    max-width: ${toVW(610, 'desktop')};
    margin-left: auto;
  }
`;

const SectionCriteriaBlock = styled(Block)`
  background-color: ${color.bg.light};
  ${screenMin('lg')} {
    padding-right: ${toVW(105, 'desktop')};
  }
`;

export {
  SectionCriteriaBase,
  SectionCriteriaIllustration,
  SectionCriteriaTitle,
  SectionCriteriaList,
  SectionCriteriaBlock,
};
