import React from 'react';
import { ContentBlock, Strings, StringsWrapper } from '@fragments';
import { useStateScreenMobile } from '@helpers/hooks';
import {
  SectionEqualitiesContainer,
  SectionEqualitiesContent,
  SectionEqualitiesVector,
} from './SectionEqualities.styled';

const SectionEqualities = () => {
  return (
    <StringsWrapper>
      {useStateScreenMobile() && <Strings name="fund-equalities--mobile" height={105} top={350} mobile />}
      <SectionEqualitiesContainer>
        <SectionEqualitiesContent>
          <ContentBlock
            headline="Inequalities in our socio-economic systems"
            content={`When only a limited group of people has access to opportunities and resources,
            the rest of the society has no way to voice their opinion and use their creativity
            or the potential to increase the quality of life.
            This hampers our general progress and creates unnecessary injustice.`}
          />
        </SectionEqualitiesContent>
        <SectionEqualitiesVector />
      </SectionEqualitiesContainer>
    </StringsWrapper>
  );
};

export default SectionEqualities;
