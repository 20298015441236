import React from 'react';
import { PageHeader } from '@fragments';
import dataFund from '../Fund.data';

const {
  sectionHeader: { name, headline, content, ctaObj, linkArray },
} = dataFund;

const SectionHeader = () => (
  <PageHeader name={name} headline={headline} content={content} ctaObj={ctaObj} linkArray={linkArray} />
);

export default SectionHeader;
