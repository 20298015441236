import styled from 'styled-components';
import { spaceDt, spaceMb, screenMin } from '@helpers/styles';
import { toVW } from '@helpers/methods';
import { Container } from '@fragments';
import { DancingDogs } from '@illustrations';

const SectionEqualitiesContainer = styled(Container)`
  flex-direction: column-reverse;
  padding-left: ${spaceMb(2)};
  ${screenMin('lg')} {
    flex-direction: row;
    padding-left: ${spaceDt(26)};
    padding-right: 0;
    margin-bottom: ${spaceDt(28)};
  }
`;

const SectionEqualitiesContent = styled.div`
  padding-left: ${spaceMb(2)};
  padding-right: ${spaceMb(4)};

  ${screenMin('lg')} {
    flex: 1;
    min-width: ${toVW(679, 'desktop')};
    padding: 0;
  }
`;

const SectionEqualitiesVector = styled(DancingDogs)`
  max-width: 100%;
  width: ${toVW(318, 'mobile')};
  height: ${toVW(333, 'mobile')};

  ${screenMin('lg')} {
    width: ${toVW(506, 'desktop')};
    height: ${toVW(529, 'desktop')};
  }
`;
export { SectionEqualitiesContainer, SectionEqualitiesContent, SectionEqualitiesVector };
