import React from 'react';
import { SEO } from '@fragments';
import ContactForm from '@features/ContactForm';
import { ContactFormHeader } from '@features/ContactForm/ContactForm.styled';
import { useStateScreenMobile } from '@helpers/hooks';
import SectionClimateChange from './sections/SectionClimateChange';
import SectionSustainability from './sections/SectionSustainability';
import SectionEqualities from './sections/SectionEqualities';
import SectionHeader from './sections/SectionHeader';
import SectionCriteria from './sections/SectionCriteria';
import SectionStatement from './sections/SectionStatement';

const Fund = () => (
  <>
    <SEO
      title="Investing in early-stage impact-driven startups | Ragnarson Fund"
      description="We are looking for startups with a positive environmental or social impact.
      We invest in ones that we believe in with cash, advisory and software development services."
    />
    <SectionHeader />

    <SectionClimateChange />

    <SectionSustainability />

    <SectionEqualities />

    <SectionCriteria />

    <SectionStatement />

    <ContactForm headline={useStateScreenMobile() ? '' : <ContactFormHeader>Apply</ContactFormHeader>} />
  </>
);

export default Fund;
