import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getFontSet, screenMin, spaceMb, spaceDt, color } from '@helpers/styles';
import { TYPE_COMPONENT } from '@helpers/types';

const BlockBase = styled.div`
  border: 1px solid ${color.accent[2]};
  padding: ${spaceMb(1.5)} ${spaceMb(2)};
  ${getFontSet('body-3', 'desktop', 'mobile')}

  ${screenMin('lg')} {
    padding: ${spaceDt(4)} ${spaceDt(4.5)};
    ${getFontSet('body-1', 'desktop')}
  }

  &:not(:last-child) {
    border-bottom: none;
  }
`;

export const Block = ({ children, className }) => <BlockBase className={className}>{children}</BlockBase>;

Block.propTypes = {
  children: TYPE_COMPONENT.isRequired,
  /* eslint-disable-next-line react/require-default-props  */
  className: PropTypes.string,
};
